import "intersection-observer";
import swiper from "./swiper";
import faq from "./faq";
import lazyload from "./dal-lazyload";
import AOS from "aos";
import "lazysizes";
import navigation from "./navigation";
import heroVideo from "./videoHero";
import heroOverlay from "./videoOverlay";
import newsletterForm from "./newsletter-form";
import marqueeImages from "./marquee-images";
import cookieBanner from "./cookie-banner";
import textTiles from "./text-tiles";
import iframeStaffcloud from "./iframe-staffcloud";
import autoSwitchSlider from "./auto-switch-slider";
import accordion from "./accordion";
import videoSlider from "./video-slider";
import heroAutoSwitchSlider from "./hero-auto-switch-slider";
import imageSlider from "./image-slider";
import applicationForm from "./application-form";
import contact from "./contact";
// import htmx from "./htmx";
import htmx from "htmx.org";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder 

(function () {

	videoSlider.init();
	
	autoSwitchSlider.init();

	// Init all swiper if there are some
	let allSwiper = document.querySelectorAll(".swiper-container");
	if (allSwiper.length) {
		allSwiper.forEach(function (swiperElement) {
			if (!swiperElement.dataset.swiperOptions) return;
			//Set swiper element and swiper options from data-attribute
			swiper.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
		})
	}

	htmx.on("htmx:afterSwap", function () {
		// recreate js after sprig reloads something
		videoSlider.init();
		autoSwitchSlider.init();

		let allSwiper = document.querySelectorAll(".swiper-container");
		if (allSwiper.length) {
			allSwiper.forEach(function (swiperElement) {
				if (!swiperElement.dataset.swiperOptions) return;
				//Set swiper element and swiper options from data-attribute
				swiper.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
			})
		}
	});

	faq.init();

	lazyload.init();

	navigation.init();

	heroVideo.createVideoHero();

	heroOverlay.init();

	newsletterForm.init();

	marqueeImages.init();

	iframeStaffcloud.init();
	
	textTiles.init();

	AOS.init();

	accordion.init();

	heroAutoSwitchSlider.init();

	imageSlider.init();

	applicationForm.init();

	contact.init();


	// remove comment if you want to add a cookie banner
	cookieBanner.init();

})();